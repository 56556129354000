import { defineStore } from 'pinia'
import { getCurrentUser } from '~/utility/fetchAPI'

export const useUser = defineStore('user', {
  state: () => ({
    currentUser: null
  }),
  getters: {
    GET_CURRENT_USER: (state) => state.currentUser,
    paid: (state) => state.currentUser?.paid,
    appliedHtmlcssTrial: (state) => state.currentUser?.applied_htmlcss_trial,
  },
  actions: {
    async getCurrentUser() {
      await getCurrentUser(useNuxtApp().$api).then((response: any) => {
        if (response.data) {
          this.currentUser = response.data.attributes
        }
      })
    },
  },
})
